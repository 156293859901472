<template>
    <div class="blockspace">

        <structure :isBlock="true" :cate="3"></structure>
   </div>
</template>

<script>
import Structure from '@/views/layout/Structure'

export default {
    components: { Structure },


};
</script>

<style lang="scss" scoped>
    .blockspace{
        padding-top:15px;
    }
</style>